import React, { useContext, useState } from 'react';

import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import s from './NavigationDesktop.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import ButtonSecondary from '../ButtonSecondary';
import classNames from 'classnames';
import { Button } from 'ariakit/button';
import { Dialog, useDialogState } from 'ariakit/dialog';
import { GradientContext, ContactContext } from '../../containers/BasePage';
import FunFactDefault from '../../public/img/funfact_default.jpg';
import { trackMenuInteraction, trackMenuContact } from '../../utils/dataLayer';

const NavigationDesktop = ({
  navigation = [],
  navigationSpotlight = {},
  navigationDefaultImage = {},
}) => {
  const contact = useContext(ContactContext);
  const { t } = useTranslation();
  return (
    <header className={`${s.Root} grid`}>
      <nav className={s.NavigationDesktop} aria-label="Menu">
        <Link href="/" legacyBehavior={true}>
          <a className={s.LogoLink}>
            <Image
              width={172}
              height={48}
              src="/img/logo.svg"
              alt="Stockholm Business Region Logotype - Go to startpage"
              className={s.Logo}
            />
          </a>
        </Link>

        {navigation?.map((navigationItem, i) => {
          return (
            <NavigationDesktopItem
              key={i}
              navigationSpotlight={navigationSpotlight}
              navigationDefaultImage={navigationDefaultImage}
              {...navigationItem}
            />
          );
        })}

        {contact?.url && (
          <ButtonSecondary
            modifiers={['NavDesktop']}
            onClick={() => {
              trackMenuContact();
            }}
            asLink={true}
            href={contact?.url}>
            {t('Generic.contactUs')}
          </ButtonSecondary>
        )}
      </nav>
    </header>
  );
};

NavigationDesktop.propTypes = {
  navigation: PropTypes.array.isRequired,
};

NavigationDesktop.defaultProps = { navigation: [] };

const NavigationDesktopItem = ({
  title,
  href,
  children = [],
  isActive,
  isCurrent,
  navigationSpotlight = {},
  navigationDefaultImage = {},
}) => {
  const classes = classNames(s.NavigationDesktopLink, {
    [s.IsActive]: isActive,
  });

  return (
    <>
      {children.length === 0 ? (
        <Link href={href} legacyBehavior={true}>
          <a
            onClick={() => {
              trackMenuInteraction(1, title);
            }}
            className={classes}
            aria-current={isCurrent ? 'page' : false}>
            {title}
          </a>
        </Link>
      ) : (
        <MegaMenu
          title={title}
          navigationSpotlight={navigationSpotlight}
          navigationDefaultImage={navigationDefaultImage}
          isActive={isActive}
          isCurrent={isCurrent}
          navChildren={children}
        />
      )}
    </>
  );
};

NavigationDesktopItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
};

const MegaMenu = ({
  title,
  isActive,
  isCurrent,
  navChildren = [],
  navigationSpotlight = {},
  navigationDefaultImage = {},
}) => {
  const [currentFunFact, setCurrentFunFact] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialogState();
  const gradient = useContext(GradientContext);

  const chevronClasses = classNames(s.Chevron, {
    [s.ChevronOpen]: isOpen,
  });

  const megaMenuButtonClasses = classNames(s.MegaMenuButton, {
    [s.MegaMenuButtonOpen]: dialog.open,
    [s.IsActive]: isActive,
  });

  const wrapperClasses = classNames(s.MegaMenuWrapper, s[gradient], {
    [s.MegaMenuOpen]: isOpen,
  });

  const imageStyle = { objectFit: 'cover', maxHeight: 870 };

  if (
    !!navigationSpotlight?.page?.image &&
    !!navigationSpotlight?.page?.image.focal &&
    !!navigationSpotlight?.page?.image.focal.x &&
    !!navigationSpotlight?.page?.image.focal.y
  ) {
    imageStyle.objectPosition = `${navigationSpotlight.page.image.focal.x} ${navigationSpotlight.page.image.focal.y}`;
  }

  const funfacts = [
    {
      id: 'default',
      image: {
        url: navigationDefaultImage?.url
          ? navigationDefaultImage.url
          : FunFactDefault,
      },
    },
    ...navChildren,
  ];

  return (
    <>
      <Button
        className={megaMenuButtonClasses}
        aria-expanded={isOpen}
        aria-controls="mega-dialog"
        onClick={() => {
          trackMenuInteraction(1, title);
          if (!isOpen) {
            dialog.toggle();
            setTimeout(() => {
              setIsOpen(true);
            }, 100);
          } else {
            setIsOpen(false);
            setTimeout(() => {
              dialog.toggle();
            }, 800);
          }
        }}>
        {title}
        <span className={chevronClasses} />
      </Button>
      <Dialog state={dialog} id="mega-dialog" className={wrapperClasses}>
        <div className={s.BlobRight1} />
        <div className={s.BlobLeft1} />
        <div className={s.BlobCenter1} />
        <div className={`${s.MegaMenu} grid`}>
          <div className={s.ImageContent} style={imageStyle}>
            {!navigationSpotlight?.page?.url &&
              funfacts.map((child, i) => {
                const classes = classNames(s.FunFact, {
                  [s.FunFactVisible]: !!currentFunFact
                    ? currentFunFact === child.id
                    : funfacts[0].id === child.id,
                });
                return (
                  <div className={classes} key={child.id}>
                    <div className={s.ImageWrapper}>
                      <Image
                        className={s.Image}
                        src={child?.image?.url}
                        alt=""
                        fill
                        style={imageStyle}
                      />
                    </div>
                    {!!child.funFactTitle && (
                      <div className={`${s.ImageGlass} black-glass`}>
                        <div className={s.ImageTitle}>{child.funFactTitle}</div>
                        <div className={s.ImageText}>{child.funFactText}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            {!!navigationSpotlight.page?.url && (
              <>
                <div className={s.ImageWrapper}>
                  <Image
                    className={s.Image}
                    src={navigationSpotlight?.page?.image?.url}
                    alt=""
                    fill
                    style={imageStyle}
                  />
                </div>
                <div className={`${s.ImageGlass} ${s.Spotlight} black-glass`}>
                  <div className={s.ImageTagline}>
                    {navigationSpotlight?.tagline}
                  </div>
                  <div className={s.ImageTitle}>
                    {navigationSpotlight?.title}
                  </div>
                  <div className={s.ImageText}>{navigationSpotlight?.text}</div>
                  {!!navigationSpotlight?.page?.url && (
                    <a
                      href={navigationSpotlight?.page?.url}
                      className={s.ImageLink}>
                      {navigationSpotlight?.page?.linkText}
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
          <div className={`${s.MegaMenuItemsWrapper}`}>
            {navChildren.map((child, i) => (
              <MegaMenuItem
                key={i}
                {...child}
                dialog={dialog}
                setCurrentFunFact={setCurrentFunFact}
              />
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};

MegaMenu.propTypes = {
  title: PropTypes.string.isRequired,
  navChildren: PropTypes.array.isRequired,
};

const MegaMenuItem = ({
  title,
  href,
  text,
  gradient,
  id,
  dialog,
  isCurrent,
  setCurrentFunFact,
}) => {
  const wrapClasses = classNames(s.MegaMenuItemWrapper, {
    [s[gradient]]: !!gradient,
  });

  return (
    <div
      onMouseOver={() => setCurrentFunFact(id)}
      onMouseOut={() => setCurrentFunFact(null)}
      className={wrapClasses}>
      <a
        aria-current={isCurrent ? 'page' : false}
        href={href}
        className={s.MegaMenuLink}
        onClick={() => {
          trackMenuInteraction(2, title);
          dialog.hide();
        }}>
        {title}
      </a>
    </div>
  );
};

MegaMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default NavigationDesktop;
