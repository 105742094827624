import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import NavigationDesktop from '../../components/NavigationDesktop';
import NavigationMobile from '../../components/NavigationMobile';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import SkipContent from '../../components/SkipContent';
import s from './BasePage.module.scss';
import classNames from 'classnames';
import { trackEvent, trackThreePageViews } from '../../utils/dataLayer';
const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));
import Survey from '../../components/Survey';
import {
  keysToCamelFromSnake,
  keysToSnakeFromCamel,
} from '../../utils/caseconverters';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

let surveyInitiated = false;

const BasePage = ({
  children,
  seo,
  gradient,
  wagtailUserbar,
  siteSetting,
  breadcrumbs,
  backgroundWhite,
  hasThreePageViews,
  noBasePageFooter,
  footerModifiers = [],
  _class,
}) => {
  const {
    seoHtmlTitle,
    seoMetaDescription,
    seoOgTitle,
    seoOgDescription,
    seoOgUrl,
    seoOgImage,
    seoOgType,
    seoTwitterTitle,
    seoTwitterDescription,
    seoTwitterUrl,
    seoTwitterImage,
    seoMetaRobots,
    canonicalLink,
  } = seo;

  const rootClasses = classNames(s.Root, {
    [s.White]: backgroundWhite,
  });

  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    if (hasThreePageViews) {
      trackThreePageViews();
    }
  }, [hasThreePageViews]);

  useEffect(() => {
    window.survey = {
      init: async (surveySlug, force = false) => {
        const hasCompletedSurvey = !!Cookies.get(
          'siteSurveyCompleted_' + surveySlug
        );

        const hasSeenSurveyThisSession = Cookies.get(
          'hasSeenSurveyThisSession'
        );

        if (
          (surveyInitiated || hasCompletedSurvey || hasSeenSurveyThisSession) &&
          !force
        ) {
          return;
        }

        surveyInitiated = true;

        if (!Cookies.get('siteSurveyUserGuid')) {
          Cookies.set('siteSurveyUserGuid', uuidv4(), {
            expires: 182,
          });
        }

        const userGuid = Cookies.get('siteSurveyUserGuid');
        const resp = await fetch(`/wt/sitesurvey/v1/surveys/${surveySlug}/`);
        let json = await resp.json();
        json = keysToCamelFromSnake(json);

        Cookies.set('hasSeenSurveyThisSession', true); // clears when browser is closed

        trackEvent('surveyInit', 'Survey', 'Survey initialized', surveySlug);
        setSurvey({
          ...json,
          userGuid,
        });
      },
    };
  }, []);

  const handleSurveyDone = (surveySlug) => {
    surveyInitiated = false;
    Cookies.set('hasSeenSurveyThisSession', true); // clears when browser is closed
    Cookies.set('siteSurveyCompleted_' + surveySlug, 1, {
      expires: 182,
    });
  };

  const handleSurveyClose = (surveySlug) => {
    surveyInitiated = false;
    Cookies.set('hasSeenSurveyThisSession', true); // clears when browser is closed
    Cookies.set('siteSurveyCompleted_' + surveySlug, 1, {
      expires: 182,
    });

    setSurvey(null);
  };

  const handleSurveySubmitAnswer = async ({
    surveySlug,
    question,
    answer,
    userGuid,
  }) => {
    let postData = {
      userGuid,
      question,
      answer,
      fromPage: window.location.href.slice(0, 255),
      fromDevice: window.navigator.userAgent.slice(0, 100),
    };
    postData = keysToSnakeFromCamel(postData);

    const resp = await fetch(
      `/wt/sitesurvey/v1/surveys/${surveySlug}/answers/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(postData),
      }
    );
    const json = await resp.json();
  };

  return (
    <>
      <Head>
        <title>{seoHtmlTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2a2a2a" />
        <meta name="msapplication-TileColor" content="#c4c4c4" />

        {!!seoMetaDescription && (
          <meta name="description" content={seoMetaDescription} />
        )}
        {!!seoOgTitle && <meta property="og:title" content={seoOgTitle} />}
        {!!seoOgDescription && (
          <meta property="og:description" content={seoOgDescription} />
        )}
        {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}
        {!!seoOgImage && <meta property="og:image" content={seoOgImage} />}
        {!!seoOgType && <meta property="og:type" content={seoOgType} />}
        {!!seoTwitterTitle && (
          <meta property="twitter:title" content={seoTwitterTitle} />
        )}
        {!!seoTwitterDescription && (
          <meta
            property="twitter:description"
            content={seoTwitterDescription}
          />
        )}
        {!!seoTwitterUrl && (
          <meta property="twitter:url" content={seoTwitterUrl} />
        )}
        {!!seoTwitterImage && (
          <meta property="twitter:image" content={seoTwitterImage} />
        )}
        <meta name="robots" content={seoMetaRobots} />
        {!!canonicalLink && <link rel="canonical" href={canonicalLink} />}
      </Head>
      <div className={classNames('BasePage', s.BasePageWrap, s[_class])}>
        <SkipContent />
        <div className={s.NavDesktop}>
          <NavigationDesktop
            navigationSpotlight={siteSetting?.navigationSpotlight}
            navigationDefaultImage={siteSetting?.navigationDefaultImage}
            navigation={siteSetting?.navigation}
          />
        </div>
        <div className={s.NavMobile}>
          <NavigationMobile navigation={siteSetting?.navigation} />
        </div>

        <Breadcrumb {...breadcrumbs} />

        <main className={rootClasses} id="main-content">
          {children}
        </main>

        {!noBasePageFooter && (
          <footer>
            <div className={`${s.FooterHr} grid`} />
            <Footer
              {...siteSetting?.footer}
              modifiers={footerModifiers}
              cookieConsent={siteSetting?.cookieConsent}
            />
          </footer>
        )}
      </div>
      {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
      {survey && (
        <Survey
          {...survey}
          onClose={handleSurveyClose}
          onDone={handleSurveyDone}
          onSubmitAnswer={handleSurveySubmitAnswer}
        />
      )}
    </>
  );
};

BasePage.defaultProps = {
  seo: {},
};

BasePage.propTypes = {
  children: PropTypes.node,
  seo: PropTypes.shape({
    seoHtmlTitle: PropTypes.string,
    seoMetaDescription: PropTypes.string,
    seoOgTitle: PropTypes.string,
    seoOgDescription: PropTypes.string,
    seoOgUrl: PropTypes.string,
    seoTwitterTitle: PropTypes.string,
    seoMetaRobots: PropTypes.string,
  }),
  wagtailUserbar: PropTypes.shape({
    html: PropTypes.string,
  }),
};

export default BasePage;
