import React, { useEffect, useState, useContext, createContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import ButtonBurger from '../ButtonBurger';
import ButtonSecondary from '../ButtonSecondary';
import {
  Dialog,
  DialogDismiss,
  DialogHeading,
  useDialogState,
} from 'ariakit/dialog';
import { Button } from 'ariakit/button';

import {
  GradientContext,
  ContactContext,
  ZoomLevelContext,
} from '../../containers/BasePage';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import s from './NavigationMobile.module.scss';
import { trackMenuInteraction, trackMenuContact } from '../../utils/dataLayer';

const NavContext = createContext();

const NavHeader = ({ isOpen, onClick, force }) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  return (
    <>
      <Link href="/" legacyBehavior={true}>
        <a className={s.LogoLink}>
          <Image
            width={151}
            height={36}
            src="/img/logo.svg"
            alt="Stockholm - capital of Scandinavia logotype, click to go to Start"
            className={s.Logo}
          />
        </a>
      </Link>
      <button
        onClick={() => {
          onClick();
          if (!isOpen) {
            setTimeout(() => {
              setHasAnimated(true);
            }, 450);
          } else {
            setHasAnimated(false);
          }
        }}
        type="button"
        aria-label={`${isOpen ? 'Close' : 'Open'} menu`}
        className={s.Burger}>
        <ButtonBurger
          isOpen={isOpen}
          forceOpen={force || (hasAnimated && isOpen)}
        />
      </button>
    </>
  );
};

const NavigationMobile = ({ navigation = [] }) => {
  const dialog = useDialogState({ animated: true });
  const [isOpen, setIsOpen] = useState(null);
  const [megaMenuOpen, setMegaMenuOpen] = useState(null);
  const gradient = useContext(GradientContext);
  const nav = useContext(NavContext);
  const browserZoomPercentage = useContext(ZoomLevelContext);

  const rootClasses = classNames(s.Root, 'grid', {
    [s.Open]: isOpen,
  });

  const dialogClasses = classNames(s.Dialog, {
    [s.IsZoomed]: browserZoomPercentage > 100,
  });

  const navClasses = classNames(s.NavigationMobile, {
    [s.Open]: megaMenuOpen,
  });

  return (
    <header className={rootClasses}>
      <nav className={navClasses} aria-label="Meny">
        <NavHeader
          isOpen={isOpen}
          onClick={() => {
            setIsOpen((pv) => !pv);
            if (isOpen) {
              dialog.hide();
            } else {
              dialog.show();
            }
          }}
        />
        <Dialog state={dialog} className={dialogClasses}>
          <div className={classNames(s.TopBlob, s[gradient])} />
          <div className={`${s.DialogContainer} grid`}>
            <div className={s.LinkContainer}>
              {navigation.map(({ title, href, children }) => {
                if (children.length === 0) {
                  return (
                    <a
                      key={href}
                      href={href}
                      onClick={() => {
                        trackMenuInteraction(1, title);
                      }}
                      className={s.NavigationMobileLink}>
                      {title}
                    </a>
                  );
                } else {
                  return (
                    <MegaMenu
                      browserZoomPercentage={browserZoomPercentage}
                      key={href}
                      href={href}
                      title={title}
                      navChildren={children}
                    />
                  );
                }
              })}
            </div>
          </div>
          <Contact />
        </Dialog>
      </nav>
    </header>
  );
};

NavigationMobile.propTypes = {
  navigation: PropTypes.array.isRequired,
};

NavigationMobile.defaultProps = { navigation: [] };

const MegaMenu = ({
  // isOpen,
  // setIsOpen,
  // setMegaMenuOpen,
  title,
  href,
  parentDialog,
  browserZoomPercentage,
  navChildren = [],
}) => {
  const dialog = useDialogState();
  const gradient = useContext(GradientContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={s.MegaMenuContainer}>
      <Button className={s.MegaMenuButton} onClick={() => setIsOpen(true)}>
        {title}
        <Image
          width={8}
          height={14}
          src="/img/icon-chevron-right-white.svg"
          alt=""
          className={s.Arrow}
        />
      </Button>
      <div className={classNames(s.MegaMenu, 'grid', { [s.IsOpen]: isOpen })}>
        <div className={classNames(s.TopBlob, s[gradient])} />
        {isOpen && (
          <>
            <div className={`${s.Container}`}>
              <div className={`${s.DialogContainer}`}>
                <Button
                  className={s.MegaMenuMenuButton}
                  onClick={() => {
                    setIsOpen(false);
                  }}>
                  <Image
                    width={16}
                    height={10}
                    src="/img/icon-chevron-right-white.svg"
                    alt=""
                    className={s.ArrowReverse}
                  />
                  Menu
                </Button>
                <a
                  href={href}
                  onClick={() => {
                    trackMenuInteraction(1, title);
                  }}
                  className={s.MegaMenuButton}>
                  {title}
                </a>
                {navChildren.map((child, i) => (
                  <a
                    key={child.href + 'mmitem'}
                    href={child.href}
                    onClick={() => {
                      trackMenuInteraction(2, child.title);
                    }}
                    className={s.MegaMenuLink}>
                    {child.title}
                  </a>
                ))}
              </div>
              {browserZoomPercentage === 100 && <Contact />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Contact = () => {
  const contact = useContext(ContactContext);
  const gradient = useContext(GradientContext);
  return (
    <div className={classNames(s.MegaMenuContact, s[gradient])}>
      <div className={s.ContactWrap}>
        <div className={s.ContactTitle}>Contact</div>
        <div className={s.ContactText}>
          We are here to help you on your Stockholm business journey.
        </div>
      </div>
      {contact?.url && (
        <ButtonSecondary
          borderColor="white"
          asLink
          onClick={() => {
            trackMenuContact();
          }}
          href={contact?.url}
          modifiers={['Circle']}>
          <Image
            width={26}
            height={20}
            src="/img/icon-arrow-right-white.svg"
            alt=""
            className={s.Contact}
          />
        </ButtonSecondary>
      )}
    </div>
  );
};

export default NavigationMobile;
